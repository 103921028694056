var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index"},[(_vm.activeStep === 1)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.qrcodeTest),expression:"qrcodeTest"}],staticClass:"test-input-terminal",attrs:{"type":"text","placeholder":'QR Code'},domProps:{"value":(_vm.qrcodeTest)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.qrcodeTest=$event.target.value}}}):_vm._e(),(_vm.activeStep === 3)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.articleEANTest),expression:"articleEANTest"}],staticClass:"test-input-terminal",attrs:{"type":"text","placeholder":'Article EAN'},domProps:{"value":(_vm.articleEANTest)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnterArticle.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.articleEANTest=$event.target.value}}}):_vm._e(),_c('transition',{attrs:{"name":"step-fade","mode":"out-in"}},[(_vm.activeStep === 1)?_c('qrcode',{class:[
        { 'hide' : _vm.loginError }
      ],attrs:{"step":_vm.activeStep}}):_vm._e()],1),_c('transition',{attrs:{"name":"step-fade","mode":"out-in"}},[(_vm.activeStep === 2)?_c('pin',{attrs:{"step":_vm.activeStep,"user":_vm.user.user,"forward":_vm.moveForward}}):_vm._e()],1),_c('transition',{attrs:{"name":"step-fade","mode":"out-in"}},[(_vm.activeStep === 3)?_c('scan',{class:[
        { 'hide' : _vm.loginError }
      ],attrs:{"step":_vm.activeStep,"user":_vm.user.user,"forward":_vm.moveForward}}):_vm._e()],1),_c('div',{class:[
      'help',
      { 'hide' : _vm.loginError }
    ]},[_c('div',{staticClass:"help-inner"},[_c('div',{staticClass:"description"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('terminal.index.help.description'))}})]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"btn btn-default btn-default-white",domProps:{"innerHTML":_vm._s(_vm.$t('terminal.index.help.manual'))},on:{"click":function($event){return _vm.dialogOpen()},"touchstart":function($event){return _vm.touchStart($event)},"touchend":function($event){return _vm.touchEnd($event);},"touchcancel":function($event){return _vm.touchEnd($event)}}})])])]),_c('dialog-default',{attrs:{"title":_vm.dialogTitle,"headline":_vm.dialogHeadline,"description":_vm.dialogDescription,"type":_vm.dialogContentType,"dialogVisible":_vm.dialogVisible,"dialogClose":_vm.dialogClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }